.social-media {
    z-index: 50;
    position: fixed;
    top: 50%;
    right: 0%;
    transform: translateY(-50%);
}

.social-media li {
    margin: 5px 0;
}

.social-media a {
    display: block;
    height: 60px;
    width: 60px;
    line-height: 60px;
    position: relative;
    transition: right .4s ease;
    right: 0;
}

.social-media span {
    padding: 0 30px 0 30px;
    position: absolute;
    right: -110px;
    transition: right .4s ease;
    font-size: 1.5rem;
}

.social-media a:hover {
    right: 110px;
}

.social-media i {
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    font-size: 1.5em;
}

.social-media i:hover {
    cursor: pointer;
}

.fab {
    padding: 20px;
    font-size: 30px;
    text-align: center;
    text-decoration: none;
    width: 60px;
}

.fa-facebook-f {
    background: #3B5998;
    color: white;
}

.fa-twitter {
    background: #55ACEE;
    color: white;
}

.fa-linkedin-in {
    background: #007bb5;
    color: white;
}
.tweet-button {
    display: inline-table;
    font-size: 1.5rem;
    background: #55ACEE;
    color: white;
    padding: 4px 8px 4px 8px;
    border-radius: 5px;
    font-weight: bold;
}
.tweet-button:hover {
    background-color: #1886C9;
    color: white;
}
.tweet-button .fab {
    margin-right: 5px !important;
    font-size: 1.5rem;
    width: unset;
    background: transparent;
    padding: 0;
    margin-right: 2px;
}
.social-container {
    display: flex;
    margin-bottom: 20px;
}