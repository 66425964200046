@font-face {
  font-family: 'Niramit';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/Niramit-Regular.ttf') format('truetype');
}
button, input {
  margin: 0;
  padding: 0;
  border: none;
  font: inherit;
  line-height: normal;
}
html {
    font-size: 62.5%;
}
body {
    font-family: 'Niramit', sans-serif;
}
h1 {
    font-size: 5.8rem;
}
h2 {
    font-size: 4.8rem;
    letter-spacing: .8px;
    font-weight: bold;
}
h3 {
    font-size: 3rem;
}
h4 {
    font-size: 2.4rem;
}
h5 {
    font-size: 2rem
}
p {
    font-size: 1.6rem;
}
a:hover {
    text-decoration: none;
}
ul {
    list-style-type: none;
    padding: 0;
}
.selected {
    color: #0b51ca !important;
}
.selected-background {
    background: #0b51ca;
}
.btn-primary {
    background-color: #0b51ca;
    border: none;
    border-radius: 0;
}
.docas-btn-primary {
    line-height: 4rem;
    color: #fff;
    height: 50px;
    width: 150px;
    box-shadow: 0 25px 42px rgba(69, 137, 254, 0.35);
    background-color: #0b51ca;
    border: none;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
}
.docas-btn-primary:hover {
    color: #fff;
    /* box-shadow: 0 25px 42px rgba(69, 137, 254, 0.35); */
    background-color:#0069d9
}
.docas-btn-outline-primary {
    color: #0b51ca;
    height: 60px;
    width: 200px;
    box-shadow: 0 25px 42px rgba(69, 137, 254, 0.35);
    background-color: #fff;
    border: 2px solid #0b51ca;
    font-size: 1.8rem;
    letter-spacing: 1.5px;
}
.docas-btn-outline-primary:hover {
    color: #fff;
    background-color: #0b51ca;
}
.docas-btn-transparent {
    color: #0b51ca;
    height: 50px;
    width: 150px;
    border: 2px solid #0b51ca;
    margin: 8px 0 0 135px;
    line-height: 45px;
    text-align: center;
}
.docas-input {
    height: 60px;
    border: 2px solid #0b51ca;
    font-size: 2.4rem;
    position: relative;
}

.input-group-text {
    width: 55px;
    background: no-repeat;
    border: 2px solid #0b51ca;
    border-left: none;
}
.white {
    color: #ffffff;
}
.margin-inline-start {
    -webkit-margin-start: unset;
    margin-inline-start: auto;
}
.send-message-success > .row {
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 250px 0;
}
.pagination li {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    font-size: 2rem;
}
.page-link-style {
    width: 50px;
}
.pag-container {
    display: flex;
    justify-content: center;
}
@media (max-width: 1600px) {
    html {
        font-size: 50% !important;
    }
}