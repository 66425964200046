.form-search {
    position: relative;
}
.filtered-form-component {
    border-top: 1px solid #ccc;
    margin: 15px 0;
    padding: 15px;
}
.form-page-main-image {
    position: absolute;
    top: 50px;
    right: 8%;
    max-width: 800px;
    width: 100%;
}
.filtered-form {
    margin-bottom: 35px;
}
.filtered-form-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    box-shadow: 0 0 42px rgba(176, 170, 170, 0.35);
    background-color: #D6E5F7;
}
.filtered-form-image {
    width: 100%;
    object-fit: cover;
    padding: 10px;
}
.filtered-form-description {
    color: #666;
    font-family: Niramit;
    font-size: 1.8rem;
}
.available-forms {
    margin-bottom: 85px;
}
.filtered-form-component .btn {
    margin-left: 12px;
}
.available-forms h2 {
    margin-bottom: 65px;
}
.form-details {
    margin-left: 25px;
    width: 100%;
}
.form-details > .row img {
    height: 16px;
    width: 16px;
}
.form-details > .row p {
    font-size: 1.5rem;
    margin: 0 6px;
}
.categories {
    padding-left: 30px;
    margin-bottom: 40px;
}
.form-category {
    margin: 20px 0 0 10px;
}
.form-category button {
    background: no-repeat;
    border: none;
    font-size: 18px;
}
.form-category span {
    font-size: 18px;
    margin-left: 7px;
}
.active-page {
    background-color: #0b51ca;
    color: #fff;
}
.filter-option {
   display: flex;
   flex-direction: column;
}

.filter-option .filter {
    display: flex;
    flex-direction: column;
 }

 .filter-option .filter label {
    font-size: 1.8rem;
 }

 .filter-option .filter select {
    font-size: 1.6rem;
    height: 40px;
    max-width: 300px;
 }

 .filter-option .filter option {
    font-size: 1.6rem;
 }

.tags {
    width: 100%;
}

@media (max-width: 1200px) {
    .form-page-main-image {
        opacity: .3;
    }
}

@media (max-width: 992px) {
    .available-forms > .row {
        flex-direction: column-reverse;
    }
}

@media (max-width: 768px) {
    .available-forms {
        margin-top: 0;
    }
    .filtered-form {
        flex-direction: column;
        align-items: center;
    }
}