.login > .row {
    justify-content: center;
}

.login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 200px 0;
    width: 100%;
}

.login-form input {
    max-width: 400px;
    width: 100%;
    padding: 10px 20px;
    font-size: 1.8rem;
    margin-bottom: 30px;
    border: none;
    border-bottom: 2px solid #4589fe;
    outline: none;
}

.login-form input:focus {
    border-bottom: 2px solid #0b51ca;
    outline: none;
}

.logged-as {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

.logged-as span {
    font-size: 2rem;
    padding: 2rem;
}

.logged-as button {
    height: 40px;
    line-height: 30px;
}

.add-content {
    margin-bottom: 50px;
}

.add-content a {
    font-size: 1.6rem;
}

.firebase-add-btn {
    display: flex;
    justify-content: center;
    border: 1px solid #0b51ca;
    padding: 10px;
    margin-bottom: 20px;
}

.fa-plus-square {
    color: #4589fe;
}

.firebase-add-btn:hover .fa-plus-square {
    color: #0b51ca;
    cursor: pointer;
}

.firebase-btns {
    display: flex;
    justify-content: flex-end;
}

.firebase-btns .far {
    color: #4589fe;
}

.firebase-btns span {
    margin: 5px;
}

.firebase-btns .far:hover {
    color: #0b51ca;
}

.firebase-modal-add-article .modal-body {
    display: flex;
    flex-direction: column;
}

.firebase-modal-delete-article .modal-body,
.firebase-modal-add-article .modal-body label {
    font-size: 2rem;
}

.firebase-modal-add-article .modal-body input {
    width: 100%;
    padding: 10px 20px;
    font-size: 1.6rem;
    margin-bottom: 10px;
    border: 2px solid #4589fe;
    outline: none;
}

.firebase-modal-add-article .modal-body textarea {
    width: 100%;
    padding: 10px 20px;
    font-size: 1.6rem;
    margin-bottom: 10px;
    border: 2px solid #4589fe;
    outline: none;
    min-height: 250px;
}

.login-form input:focus {
    border-bottom: 2px solid #0b51ca;
    outline: none;
}

.firebase-article .article-details span {
    font-size: 2rem;
    font-weight: bold;
}

.firebase-article .article-details div {
    margin: 10px 0;
}

.firebase-article .article-details .adminNewsImg img {
    max-width: 250px;
}

.firebaseNews .btn-link {
    font-size: 1.8rem;
}

.firebaseNews .card-header .mb-0 {
    white-space: normal;
    word-break: break-all;
}

.firebaseNews .card-header span {
    font-size: 1.6rem;
}
.ql-container .ql-editor {
    min-height: 150px;
}