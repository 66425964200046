.logo-footer {
    width: 177px;
}
footer {
    min-height: 230px;
}
.shortcut-menu a {
    color: #39436f;
    border-bottom: 1px solid #39436f;
}
.report {
    width: 100px;
    height: 40px;
    font-size: 1.6rem !important;
    line-height: 30px;
}
.p-footer {
    height: 40px;
    opacity: 0.7;
    color: #39436f;
    font-family: Niramit;
    font-size: 1.6rem;
    font-weight: 400;
}
.footer-logo p {
    padding: 50px 0;
}
.footer-social-media {
    display: none;
}

@media (max-width: 1200px) {
    footer {
        height: unset;
        margin-bottom: 50px;
    }
    .footer-logo {
        display: flex;
        justify-content: center;
        width: 100%;
        flex-direction: column;
        align-items: center;
    }
    .footer-logo p {
        padding: 0;
    }
    .docas24-footer-nav,
    .docas24-footer-nav ul {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
    .docas24-footer-nav p {
        line-height: 30px;
        padding: 0 30px;
    }
}

@media all and (max-device-width: 992px) {
    .social-media {
        display: none;
    }
    .footer-social-media {
        display: block;
    }
    .social-media-footer i {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    footer .fab {
        width: 80px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
    }
    .dotation-image {
        width: 33rem;
    }
}

@media (max-width: 768px) {
    footer {
        margin: 50px 0 20px 0;
    }
    .logo-footer {
        width: 140px;
    }
}

@media (max-width: 576px) {
    .footer-logo {
        justify-content: center;
        flex-wrap: wrap;
    }
    .footer-logo * {
        padding: 0 10px !important;
    }
}