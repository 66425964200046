.contact {
    position: relative;
}
.contact img {
    position: absolute;
    top: 148px;
    right: 185px;
    width: 485px;
}
.contact-us {
    margin-top: 280px;
}
.contact-us h2 {
    font-size: 6.2rem;
    line-height: 7.5rem;
    font-weight: bold;
}
.contact-us p {
    font-size: 2.1rem;
    line-height: 3.6rem;
    margin-top: 38px;
}
.contact-details {
    position: relative;
    margin-top: 100px;
    margin-bottom: 50px;
    overflow: hidden;
}
.contact-details h3 {
    font-size: 48px;
    line-height: 62px;
    font-weight: bold;
    margin-bottom: 90px;
}
.contact-details img {
    position: absolute;
    top: 80px;
    right: -100px;
    width: 424px;
}
.contact-details-info iframe {
    width: 100%;
    height: 600px;
    border: 0;
}
.contact-details-info form {
    padding: 0 15px 50px 45px;
    overflow: hidden;
}
.contact-details-info form input.contact-field {
    height: 35px;
    margin: 26px 0;
    border: none;
    border-bottom: 2px solid #4589fe;
    background: transparent;
}
.contact-details-info form input.contact-field.col-6 {
    width: 49%;
}
.contact-details-info form input.contact-field.col-6:nth-child(2) {
    margin-right: 1%;
}
.contact-details-info form input.contact-field.col-6:nth-child(3) {
    margin-left: 1%;
}
.contact-details-info form textarea {
    height: 200px;
    padding: 10px;
    resize: none;
    border: 2px solid #4589fe;
    background: transparent;
}
.contact-details-info form input.contact-field,
.contact-details-info form textarea {
    font-size: 1.6rem;
}
.contact-details-info form input.contact-field:focus {
    border-bottom: 2px solid #0b51ca;
    outline: none;
}
.contact-details-info form textarea:focus {
    border: 2px solid #0b51ca;
}
.form-checkbox-btns {
    padding: 25px 0 0 5px;
}
.form-checkbox-btn {
    display: flex;
    flex-direction: column;
}
.form-checkbox-btn input[type="checkbox"] {
    display: none;
}
.form-checkbox-btn p {
    display: inline-block;
    margin-left: 10px;
}
.form-checkbox-btn span {
    display: flex;
}
.form-checkbox-btn i {
    color: #0b51ca;
}
.contact-details-info .docas-btn-primary {
    margin-top: 25px;
}
.contact-details-blocks {
    display: flex;
    justify-content: space-between;
    margin: 50px auto;
}
.contact-details-blocks > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 252px;
    max-width: 348px;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 0 37px rgba(243,216,255,0.35);
}
.contact-details-blocks h5 {
    margin-top: 60px;
    font-weight: bold;
    margin-bottom: 30px;
}
.contact-details-blocks span {
    color: #0b51ca;
    font-size: 2rem;
}
.contact-details-blocks p {
    font-weight: normal;
}
.company-info {
    width: 100%;
    margin-bottom: 50px;
}
.contact-info-column {
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.contact-info-column h5 {
    margin-bottom: 3rem;
}
.contact-image-column {
    text-align: center;
}
.contact-image-column img {
    width: 60%;
}
@media (max-width: 1200px) {
    .contact img {
        opacity: .4;
    }
}
@media (max-width: 992px) {
    .contact-details-info .col-lg-6:nth-of-type(1) {
        order: 1;
    }
}
@media (max-width: 768px) {
    .contact img {
        right: unset;
    }
    .contact-details-blocks {
        padding: 0;
    }
    .contact-image-column img {
        display: none;
    }
    .contact-info-column {
        flex: none;
        max-width: none;
        border-radius: 10px;
        box-shadow: 0 0 37px rgba(243,216,255,0.35);
        margin: 0 auto;
        width: 90%;
    }
}
@media (max-width: 576px) {
    .contact-us {
        margin-top: 150px;
    }
    .contact img {
        width: 95%;
    }
    .contact-details {
        margin-top: 200px;
    }
    .contact-details-blocks {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .contact-details-info form {
        padding: 0 0 50px 0;
    }
}
