/* FindForm component */
.find-form {
    position: relative;
}
.find-form-image {
    position: absolute;
    top: 10%;
    right: 180px;
    max-width: 820px;
}
.last-form {
    max-width: 1920px;
    margin-top: 80px;
}
.block-find-form {
    display: flex;
    flex-direction: column;
    padding: 192px 0;
}
.docas-find-form-button {
    height: 60px;
    width: 200px;
    margin: 19px auto;
    background-color: #0b51ca;
    border: none;
    font-size: 1.8rem;
    line-height: 55px;
    box-shadow: 0 25px 42px rgba(69, 137, 254, 0.35);
}
.docas-show-more {
    text-align: center;
}
.docas-show-more-button {
    line-height: 60px;
    height: 60px;
    width: 200px;
    margin-top: 10px;
    padding: 0 30px;
    background-color: #0b51ca;
    border: none;
    font-size: 2rem;
    box-shadow: 0 25px 42px rgba(69, 137, 254, 0.35);
}
.block-find-form h1 {
    margin-bottom: 58px;
}
/* ListForm component */
.square {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 570px;
    width: 100%;
    height: 44rem;
    margin: 1.4rem;
    background-color: #d9e8f7;
    border-radius: 5px;
}
.square .nav {
    height: 60px;
    margin: 34px 19px 34px 0;
}
.square .badge {
    font-size: 2.2rem;
    height: 45px;
    max-width: 110px;
    padding: 10px 18px;
    margin: 18px 25px;
    font-weight: normal;
}
.square-btns {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
}
.square-btns li a, .square-btns li button {
    padding: 8px 18px;
    margin-left: 6px;
    font-size: 2.1rem;
    cursor: pointer;
}
.defined-image-form-list {
    max-width: 34rem;
    margin: 0 auto;
    width: 100%;
}
.default-image-form-list {
    width: 24rem;
    margin: 0 auto;
}
.form-filter {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 40px;
    margin-top: 50px;
}
.form-filter p {
    margin: 0 0 0 35px;
    line-height: 40px;

}
.form-filter h3 {
    line-height: 44px;
    margin: 0 0 20px 28px;;
}
.form-btns-filters {
    display: flex;
    justify-content: center;
    margin: 60px 0 0;
    flex-wrap: wrap;
}
.form-btns-filters .btn {
    font-size: 2.1rem;
}
.form-btns-filters .btn-link {
    font-size: 2.1rem;
    color: black;
    text-decoration: none;
}
.form-btns-filters .btn-link:hover {
    border-bottom: 2px solid #0b51ca;
    border-radius: 0;
}
/* explore component */
.explore-docas {
    position: relative;
    margin-top: 100px;
    overflow: hidden;
}
.explore-docas > .row > img {
    position: absolute;
    right: -70px;
    top: 35px;
}
.explore-docas-text {
    width: 475px;
    margin: 0 0 0 122px;
}
.explore-docas-text p {
    font-size: 2.3rem;
    margin: 56px 0 0 4px;
    line-height: 37px;
}
.explore-docas-btns {
    margin: 70px 0 0 7px;
}
.explore-docas-btns a {
    line-height: 50px;
    height: 61px;
    width: 200px;
    margin-right: 12px;
    font-size: 2rem;
    text-transform: uppercase;
}
.macbook {
    display: flex;
    justify-content: center;
    padding: 32px 0px;
}
/* statistics component */
.statistics {
    position: relative;
    overflow: hidden;
    padding-bottom: 50px;
}
.statistics img {
    position: absolute;
    top: 150px;
    left: -65px;
}
.statistics-row {
    justify-content: space-between;
}
.statistics-value {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 215px;
    height: 215px;
    border-radius: 108px;
    box-shadow: 0px 22px 30px rgba(69,137,254,0.35);
    background-color: #ffffff;
    padding: 30px 0 0 0;
}
.statistics-value h5, .statistics-value p, .statistics-value span {
    font-weight: bold;
}
.statistics-row .statistics-value:nth-child(1) {
    margin: 185px 0 0;
}
.statistics-row .statistics-value:nth-child(2) {
    margin: 50px 0 0;
}
.statistics-row .statistics-value:nth-child(3) {
    margin: 235px 0 0;
}
.statistics-row .statistics-value:nth-child(4) {
    margin: 125px 0 0;
}
.statistics-value h4 {
    font-weight: bold;
}
.statistics-value p {
    font-size: 1.8rem;
}
.statistics-value span {
    font-size: 5.5rem;
}
/* news coponent */
.news {
    margin-top: 100px;
}
.news h2 {
    font-size: 5.5rem;
    text-align: center;
    margin-bottom: 50px;
}
.news .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
.news-content {
    padding: 40px;
    width: 100%;
    max-width: 600px;
    margin: 10px;
    position: relative;
}
.news-square {
    height: 300px;
    /* background-color: #d9e8f7; */
    border-radius: 5px;
}
.news-text {
    /* margin: 32px 0 40px 48px; */
    min-height: 220px;
}
.news-text h3 {
    font-size: 2.4rem;
    line-height: 32px;
    color: #0b51ca;
}
.news-text p {
    font-size: 2.4rem;
    line-height: 28px;
    margin-top: 24px;
}
.news-content:hover h3 {
    font-weight: bold;
}
.news img {
    width: 100%;
    height: 300px;
    object-fit: cover;
}
/* review component */
.block-reviews {
    margin-top: 100px;
    margin-bottom: 120px;
    max-width: 1920px;
}
.block-reviews h2 {
    margin-bottom: 100px;
}
.review-btn {
    margin: 0 10px;
    border-radius: 50%;
    border: none;
    width: 74px;
    height: 74px;
    box-shadow: 0 45px 62px rgba(51, 153, 255, 0.35);
    background-color: #ffffff;
    transition: .3s;
}
.reviews-btn-block {
    margin-top: 40px;
}
.review-btn:hover {
    background-color: #0b51ca;
}
.review-btn:hover i {
    color: #ffffff;
}
.review-btn:focus {
    border: none;
    outline: none;
}
.review-text h3 {
    margin-bottom: 10px;
}

.review-text h5 {
    margin-bottom: 20px;
}
.review-text {
    padding: 0 40px;
}

[alt="lines"] {
    z-index: -100;
}
.modal-image {
    width: 100%;
}
.modal-list p {
    font-weight: 400;
}
.fa-search {
    position: absolute;
    align-self: center;
    right: 10px;
}
@media (max-width: 1700px) {
    .find-form-image {
        right: 100px;
        width: 730px;
    }
}
@media (max-width: 1400px) {
    .find-form-image {
        opacity: .3;
    }
}
@media (max-width: 1200px) {
    .find-form-image {
        right: 15px;
        opacity: 0.3;
    }
    .statistics {
        display: none;
    }
    [alt="lines"] {
        display: none;
    }
    .explore-docas {
        margin-top: 150px;
    }
    .explore-docas-text {
        width: 100%;
        margin: 0;
    }
    .explore-docas-text h2 {
        text-align: center;
    }
    .explore-docas-btns {
        margin-left: 0;
        text-align: center;
    }
    .news-text {
        margin: 32px 0 0 0;
    }
    .news {
        margin-top: 150px;
    }
    .block-reviews {
        margin: 150px auto;
    }
}

@media (max-width: 992px) {
    .find-form-image {
        opacity: 0.3;
        width: 100%;
        right: 50%;
        transform: translateX(50%);
    }
    .square {
        margin: 0;
    }
    .form-filter {
        padding: 0;
    }
    .defined-image-form-list {
        padding: 0 20px;
    }
}

@media (max-width: 768px) {
    .square {
        max-width: 100%;
    }
    .macbook {
        display: block;
    }
    .macbook img {
        width: 100%;
    }
    .explore-docas-btns button {
        height: 50px;
        width: 150px;
    }
    .news {
        display: none;
    }
    .block-reviews {
        display: none;
    }
    .block-last-form {
        margin-top: 0;
    }
    .block-find-form {
        padding: 50px 0;
    }
}

@media (min-width: 992px) {
    .news .row {
        flex-wrap: nowrap;
    }
}