.bg-light {
    background-color: unset !important;
}
.header {
    margin-top: 58px;
}
nav a {
    font-size: 2.1rem;
    line-height: 60px;
    color: #000;
}
.logo {
    width: 275px;
    margin-left: 14px;
}
.nav-item {
    padding: 2px 30px 0 0;
}
.nav-item li:last-child {
    padding: 0;
}
.nav-docas-input {
    height: 60px;
    width: 210px;
}
#navbarCollapse {
    justify-content: center;
}
#navbarCollapse input {
    width: 210px;
}
.hamburger--slider {
    outline: none !important;
    z-index: 200;
}
.hamburger-inner,
.hamburger-inner::after,
.hamburger-inner::before {
    background-color: #0b51ca !important;
}
.log-in {
    width: 150px;
    height: 51px;
    line-height: 40px;
    font-size: 1.8rem;
    font-weight: bold;
    letter-spacing: 0.8px;
    border: 2px solid #0b51ca;
    color: #0b51ca;
}

@media (max-width: 992px) {
    .header {
        display: flex;
        position: relative;
        height: 170px;
        box-shadow: 0px 15px 22px rgba(69, 137, 254, 0.35);
        background-color: #ffffff;
        border-bottom: 2px solid #0b51ca;
        margin: 0;
    }
    .navbar {
        flex: 1;
    }
    .navbar-collapse {
        width: 100%;
        background: rgb(255, 255, 255);
        position: absolute;
        top: 0px;
        z-index: 100;
        border-bottom: 2px solid rgb(11, 81, 202);
        box-shadow: rgba(69, 137, 254, 0.35) 0px 15px 22px;
    }
    .navbar-nav {
        padding: 100px 0 20px;
        text-align: center;
    }
    .navbar-nav > li:not(:last-child) {
        border-bottom: 1px solid #0b51ca;
    }
    .navbar-nav > li:last-child {
        margin-top: 50px;
    }
    .nav-item {
        padding: 0;
        justify-content: center;
    }
}

@media (max-width: 1400px) {
    .navbar-brand,
    .navbar-nav,
    .logoUE {
        margin: 0 !important;
    }
}

@media (max-width: 575.98px) {
    .logo {
        width: 200px;
        margin: 0;
    }
    .logoUE {
        width: 65px !important;
    }
}

@media (min-width: 992px) {
    .log-in {
        /* margin: 8px 0 0 105%; */
    }
}

@media (min-width: 1200px) {
    .log-in {
        /* margin: 8px 0 0 134px; */
    }
}
